import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { useKeenSlider } from 'keen-slider/react'
import { useContext, useId, useState } from 'react'

import { SanityQuoteCarouselBlock } from '@data/sanity/queries/types/blocks'
import { flipAnimation } from '@lib/animate'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import Icon from '@components/icon'

type QuoteCarouselProps = Pick<SanityQuoteCarouselBlock, 'quotes'>

type QuoteCarouselItemProps = Pick<
  QuoteCarouselProps['quotes'][number],
  'author' | 'quote'
> & {
  className?: string
}

const QuoteCarouselItem = ({
  author,
  quote,
  className,
}: QuoteCarouselItemProps) => {
  return (
    <div className={cx('md:flex overflow-hidden', className)}>
      <div className="w-full md:w-1/3">
        <BlockContent
          blocks={author}
          serializers={serializers}
          renderContainerOnSingleChild
          className="rc md:pr-5"
        />
      </div>
      <div className="w-full md:w-2/3">
        <BlockContent
          blocks={quote}
          serializers={serializers}
          renderContainerOnSingleChild
          className="rc max-w-4xl"
        />
      </div>
    </div>
  )
}

const QuoteCarousel = ({ quotes }: QuoteCarouselProps) => {
  const strings = useContext(StringsContext)

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    selector: '.quote-slide',
    loop: true,
    drag: true,
    defaultAnimation: {
      duration: 800,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  const id = useId()

  return (
    <div>
      {/* Carousel slides - quotes */}
      <div
        ref={sliderRef}
        className="flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y"
      >
        {quotes?.map(({ _key, author, quote }) => (
          <QuoteCarouselItem
            key={_key}
            author={author}
            quote={quote}
            className="quote-slide"
          />
        ))}
      </div>

      {/* Carousel controls - next button, previous button, current index and total */}
      {quotes && quotes.length > 0 && (
        <div className="flex">
          <button
            onClick={() => slider.current?.prev()}
            aria-label={strings.carouselLeftArrowLabel}
            className="w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300"
          >
            <Icon
              id={`quote-carousel-previous-${id}`}
              name="ArrowLeft"
              className="block"
            />
          </button>

          <button
            onClick={() => slider.current?.next()}
            aria-label={strings.carouselRightArrowLabel}
            className="w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300"
          >
            <Icon
              id={`quote-carousel-next-${id}`}
              name="ArrowRight"
              className="block"
            />
          </button>

          <div className="ml-2 flex space-x-1 py-2">
            <div className="relative flex flex-col overflow-hidden">
              <div className="relative overflow-hidden">
                <AnimatePresence initial={false}>
                  <motion.span
                    key={currentSlide + 1}
                    initial="hide"
                    animate="show"
                    exit="hide"
                    variants={flipAnimation}
                    className="block will-change-transform absolute inset-x-0 top-0 last:static last:inset-x-auto last:top-auto"
                  >
                    {currentSlide + 1}
                  </motion.span>
                </AnimatePresence>
              </div>
            </div>
            <div>{strings.carouselCounterText}</div>
            <div>{quotes?.length ?? 0}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuoteCarousel
