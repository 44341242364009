import { useContext } from 'react'

import {
  SanityBlogPostWithoutBody,
  SanityBlogPostOptions,
} from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { getPageUrl, PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

const BlogPostCard = ({ post, options }: BlogPostCardProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  if (!post) {
    return null
  }

  return (
    <SimpleLink
      href={getPageUrl(PageType.BLOG_POST, post.slug)}
      className="cursor-pointer"
    >
      {post.coverImage && options?.showCoverImage && (
        <Photo
          className="mb-5"
          image={{
            ...post.coverImage,
            customRatio: 1.7777777778,
            borderRadius: 'lg',
          }}
        />
      )}

      <h3 className="mb-4">{post.title}</h3>

      {options?.showExcerpt && <p className="mb-6">{post.excerpt}</p>}

      {options?.showCategories && (
        <div className="flex flex-wrap mb-4">
          {post.categories?.map((category) => (
            <SimpleLink
              key={`${post.slug}-category-${category.slug}`}
              href={getPageUrl(PageType.BLOG_CATEGORY, category.slug)}
              className="mr-2 text-[12px] opacity-50"
            >
              {category.name}
            </SimpleLink>
          ))}
        </div>
      )}

      {(options?.showAuthorPhoto ||
        options?.showAuthorName ||
        options?.showDate) && (
        <div className="flex items-center">
          {options?.showAuthorPhoto && post.author?.photo && (
            <Photo
              image={post.author?.photo}
              objectFit="cover"
              forceWidth={40}
              forceHeight={40}
              className="rounded-full overflow-hidden shrink-0 w-[40px] h-[40px] mr-2"
            />
          )}
          <div>
            {options?.showAuthorName && (
              <p className="text-sm">
                {strings.blogPostBy}{' '}
                <SimpleLink
                  href={getPageUrl(PageType.BLOG_AUTHOR, post.author?.slug)}
                >
                  {post.author?.name}
                </SimpleLink>
                {options?.showDate
                  ? `, ${getFormattedDate(post.date, locale)}`
                  : ''}
              </p>
            )}
          </div>
        </div>
      )}
    </SimpleLink>
  )
}

export default BlogPostCard
