import cx from 'classnames'

import {
  SanityAccordionsBlock,
  SanityAccountAddressDetailsBlock,
  SanityAccountDetailsBlock,
  SanityAccountOrderListBlock,
  SanityAccountProductListBlock,
  SanityBlocks,
  SanityBlockType,
  SanityBlogPostCardBlock,
  SanityCalendlyWidgetBlock,
  SanityCollectionCarouselBlock,
  SanityContactFormBlock,
  SanityContentCarouselBlock,
  SanityCookieDeclarationBlock,
  SanityDemoFormBlock,
  SanityFreeformBlock,
  SanityIframeBlock,
  SanityLoginFormBlock,
  SanityNewsletterBlock,
  SanityQuoteCarouselBlock,
  SanityPasswordRecoveryFormBlock,
  SanityPredefinedContentBlockBlock,
  SanityProductBundleFormBlock,
  SanityProductCardBlock,
  SanitySignupFormBlock,
  SanityTeamMemberCardBlock,
  SanityVideoBlock,
} from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import BlogPostCard from '@blocks/blog/blog-post-card'
import CalendlyWidget from '@blocks/calendly-widget'
import ContactForm from '@blocks/contact-form'
import ContentCarousel from '@blocks/content-carousel'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import DemoForm from '@blocks/demo-form'
import Freeform from '@blocks/freeform'
import Newsletter from '@blocks/newsletter'
import CollectionCarousel from '@blocks/shop/collection-carousel'
import ProductBundleForm from '@blocks/shop/product-bundle-form'
import ProductCard from '@blocks/shop/product-card'
import TeamMemberCard from '@blocks/team-member-card'
import AccountAddressDetails from './account/account-address-details'
import AccountDetails from './account/account-details'
import AccountOrderList from './account/account-order-list'
import AccountProductList from './account/account-product-list'
import LoginForm from './account/login-form'
import PasswordRecoveryForm from './account/password-recovery-form'
import SignupForm from './account/signup-form'
import VideoModule from './video'
import QuoteCarousel from '@blocks/quote-carousel'

interface GridBlockProps {
  block: SanityBlocks
}

const GridBlock = ({ block }: GridBlockProps) => {
  switch (block._type as SanityBlockType) {
    case SanityBlockType.FREEFORM: {
      const { content, textAlign, maxWidth } = block as SanityFreeformBlock

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case SanityBlockType.ACCORDIONS: {
      const { items } = block as SanityAccordionsBlock

      return <Accordions items={items} />
    }

    case SanityBlockType.PRODUCT_CARD: {
      const { product } = block as SanityProductCardBlock

      return (
        <ProductCard
          product={product}
          hasVisuals
          showThumbnails
          showOption
          showPrice
          isInline
        />
      )
    }

    case SanityBlockType.SIGNUP_FORM: {
      const { active, authStrings } = block as SanitySignupFormBlock

      if (!active) {
        return null
      }

      return <SignupForm authStrings={authStrings} className="mt-16 mb-10" />
    }

    case SanityBlockType.LOGIN_FORM: {
      const { active, authStrings } = block as SanityLoginFormBlock

      if (!active) {
        return null
      }

      return <LoginForm authStrings={authStrings} className="mt-16 mb-10" />
    }

    case SanityBlockType.PASSWORD_RECOVERY_FORM: {
      const { active, authStrings } = block as SanityPasswordRecoveryFormBlock

      if (!active) {
        return null
      }

      return (
        <PasswordRecoveryForm
          authStrings={authStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case SanityBlockType.ACCOUNT_DETAILS: {
      const { active, accountStrings } = block as SanityAccountDetailsBlock

      if (!active) {
        return null
      }

      return (
        <AccountDetails accountStrings={accountStrings} className="mb-10" />
      )
    }

    case SanityBlockType.ACCOUNT_ADDRESS_DETAILS: {
      const { active, accountStrings } =
        block as SanityAccountAddressDetailsBlock

      if (!active) {
        return null
      }

      return (
        <AccountAddressDetails
          accountStrings={accountStrings}
          className="mt-10 mb-10"
        />
      )
    }

    case SanityBlockType.ACCOUNT_PRODUCT_LIST: {
      const { active, accountStrings } = block as SanityAccountProductListBlock

      if (!active) {
        return null
      }

      return (
        <AccountProductList accountStrings={accountStrings} className="mb-16" />
      )
    }

    case SanityBlockType.ACCOUNT_ORDER_LIST: {
      const { active, accountStrings } = block as SanityAccountOrderListBlock

      if (!active) {
        return null
      }

      return (
        <AccountOrderList accountStrings={accountStrings} className="mb-16" />
      )
    }

    case SanityBlockType.VIDEO: {
      const {
        type,
        vimeoVideo,
        muxVideo,
        settings,
        aspectRatio,
        borderRadius,
      } = block as SanityVideoBlock

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }

    case SanityBlockType.COLLECTION_CAROUSEL: {
      const { collection } = block as SanityCollectionCarouselBlock

      return <CollectionCarousel collection={collection} />
    }

    case SanityBlockType.NEWSLETTER: {
      const {
        _key,
        title,
        description,
        variant,
        service,
        hubSpotFormId,
        klaviyoListID,
        submit,
        successMsg,
        errorMsg,
        terms,
      } = block as SanityNewsletterBlock

      return (
        <Newsletter
          id={_key}
          title={title}
          description={description}
          variant={variant}
          service={service}
          hubSpotFormId={hubSpotFormId}
          klaviyoListID={klaviyoListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case SanityBlockType.PRODUCT_BUNDLE_FORM: {
      const { productBundle, showGallery } =
        block as SanityProductBundleFormBlock

      return (
        <ProductBundleForm
          productBundle={productBundle}
          showGallery={showGallery}
        />
      )
    }

    case SanityBlockType.BLOG_POST_CARD: {
      const { post, options } = block as SanityBlogPostCardBlock

      return <BlogPostCard post={post} options={options} />
    }

    case SanityBlockType.CONTENT_CAROUSEL: {
      const { items } = block as SanityContentCarouselBlock

      return <ContentCarousel items={items} />
    }

    case SanityBlockType.CALENDLY_WIDGET: {
      const { url, hideEventDetails, hideCookieSettings } =
        block as SanityCalendlyWidgetBlock

      return (
        <CalendlyWidget
          url={url}
          hideEventDetails={hideEventDetails}
          hideCookieSettings={hideCookieSettings}
        />
      )
    }

    case SanityBlockType.COOKIE_DECLARATION: {
      const { active, cookieBotId } = block as SanityCookieDeclarationBlock

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case SanityBlockType.DEMO_FORM: {
      const {
        _key,
        service,
        hubSpotFormId,
        hubSpotNewsletterFormId,
        klaviyoListID,
        klaviyoNewsletterListID,
        submit,
        analytics,
        successMsg,
        errorMsg,
        terms,
        newsletterStatement,
        buttonStyle,
        strings,
      } = block as SanityDemoFormBlock

      return (
        <DemoForm
          id={_key}
          service={service}
          hubSpotFormId={hubSpotFormId}
          hubSpotNewsletterFormId={hubSpotNewsletterFormId}
          klaviyoListID={klaviyoListID}
          klaviyoNewsletterListID={klaviyoNewsletterListID}
          submit={submit}
          analytics={analytics}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          newsletterStatement={newsletterStatement}
          buttonStyle={buttonStyle}
          strings={strings}
        />
      )
    }

    case SanityBlockType.IFRAME: {
      const { title, url, width, height } = block as SanityIframeBlock

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case SanityBlockType.TEAM_MEMBER_CARD: {
      const { teamMember } = block as SanityTeamMemberCardBlock

      return <TeamMemberCard teamMember={teamMember} />
    }

    case SanityBlockType.CONTACT_FORM: {
      const {
        _key,
        service,
        clickUpListId,
        dynamicParameters,
        submit,
        analytics,
        successMsg,
        errorMsg,
        terms,
        buttonStyle,
      } = block as SanityContactFormBlock

      return (
        <ContactForm
          id={_key}
          service={service}
          clickUpListId={clickUpListId}
          dynamicParameters={dynamicParameters}
          submit={submit}
          analytics={analytics}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          buttonStyle={buttonStyle}
        />
      )
    }

    case SanityBlockType.PREDEFINED_CONTENT_BLOCK: {
      const { contentBlocks } = block as SanityPredefinedContentBlockBlock

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case SanityBlockType.QUOTE_CAROUSEL: {
      const { quotes } = block as SanityQuoteCarouselBlock

      return <QuoteCarousel quotes={quotes} />
    }
  }
}

export default GridBlock
